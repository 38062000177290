import React from 'react';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { StringParam, useQueryParam } from 'use-query-params';

import TrackPage from './TrackPage';
import TrackJoinPage from './TrackJoinPage';
import Layout from './Layout';
import { Box } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Header from './BasicHeader';
import { TRACK_FRAGMENT } from '../../gatsby-fragments';

const GET_PRICE = gql`
  query GetPrice($token: String!) {
    priceByToken(token: $token) {
      id
      name
      displayText
      amountCents
      bundle {
        discounts {
          amountCents
          min
        }
        prices {
          id
          name
          displayText
          amountCents
        }
      }
      track {
        ${TRACK_FRAGMENT}
      }
    }
  }
`;

const TrackPricePage = () => {
  const [id, _setId] = useQueryParam('id', StringParam);
  const { data, error } = useQuery(GET_PRICE, {
    variables: { token: id },
  });

  const price = data ? data.priceByToken : null;

  if (error) {
    return (
      <Layout>
        <Header />
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          marginTop={10}
        >
          <Alert severity="warning">{error.message}</Alert>
        </Box>
      </Layout>
    );
  }

  if (price) {
    const track = { ...price.track, activePrice: price };
    if (price.amountCents || price.bundle) {
      return <TrackPage pageContext={{ track }} />;
    } else {
      return <TrackJoinPage pageContext={{ track }} />;
    }
  }
  return null;
};

export default TrackPricePage;
