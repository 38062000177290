import { useMutation, useQuery } from '@apollo/client';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Link } from 'gatsby';
import gql from 'graphql-tag';
import React, { useEffect, useMemo, useState } from 'react';
import Linkify from 'react-linkify';
import { StringParam, useQueryParam } from 'use-query-params';
import useCurrentUser from '../hooks/useCurrentUser';
import Header from './BasicHeader';
import Footer from './Footer';
import Layout from './Layout';
import DivisionStep from './track/DivisionStep';
import { isMobile } from './styles';
const unified = require('unified');
const markdown = require('remark-parse');
const html = require('remark-html');

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    avatar: {
      marginBottom: theme.spacing(1),
      width: '3rem',
      height: '3rem',
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      whiteSpace: 'pre-line',
    },
    button: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    accept: {
      padding: theme.spacing(0.5),
    },
    descriptionContainer: {
      textAlign: 'left',
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      padding: theme.spacing(2),
      backgroundColor: theme.palette.grey[100],
    },
    openApp: {
      display: 'none',
      [theme.breakpoints.down('xs')]: {
        marginTop: 20,
        display: 'block',
      },
    },
  })
);

const TOGGLE_TRACK_MUTATION = gql`
  mutation ToggleTrack($id: ID!) {
    trackRequestToggle(trackId: $id) {
      id
      userActions
    }
  }
`;

const GET_TRACK = gql`
  query GetTrack($id: ID!) {
    track(id: $id) {
      id
      userActions
      compRegistration {
        id
        gender
        ageGroup {
          name
        }
        weightGroupOptions {
          name
          value
          details
        }
        divisionOptions {
          name
          value
          details
        }
      }
    }
  }
`;

const STEPS = {
  login: 'Login',
  division: 'Division',
  join: 'Access Request',
  confirmation: 'Confirmation',
};
const COMP_STEPS = ['login', 'division', 'join', 'confirmation'];
const REGULAR_STEPS = ['login', 'join', 'confirmation'];

const TrackJoinPage = ({ pageContext }) => {
  const { track } = pageContext;
  const description = useMemo(() => {
    if (pageContext.description) return pageContext.description;
    const content = !!track.registrationDescription
      ? unified()
          .use(markdown)
          .use(html)
          .processSync(track.registrationDescription)
          .toString()
      : null;

    return content;
  }, []);
  const [tokenParam, setTokenParam] = useQueryParam('token', StringParam);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [trackLoaded, setTrackLoaded] = useState(false);

  const { currentUser, redirectToAuth, savePersistedToken } = useCurrentUser();

  const [toggleTrackMutation, { loading }] = useMutation(TOGGLE_TRACK_MUTATION);

  const { data, refetch } = useQuery(GET_TRACK, {
    variables: { id: track.id },
    skip: !currentUser,
    onCompleted: () => setTrackLoaded(true),
  });

  const userActions =
    data && data.track.userActions ? data.track.userActions : [];

  const canJoin = userActions.includes('join');
  const canRequestAccess = userActions.includes('request-access');

  const isPendingApproval = userActions.includes('pending-approval');
  const hasJoined = userActions.includes('joined');
  const isRegistered = isPendingApproval || hasJoined;

  const allowRegistration =
    !trackLoaded || canJoin || canRequestAccess || hasJoined || isRegistered;

  const styles = useStyles();
  const [activeStep, setActiveStep] = useState('login');

  useEffect(() => {
    if (tokenParam) {
      setTokenParam(undefined, 'replaceIn');
      savePersistedToken(tokenParam);
    }
  }, [tokenParam]);

  const steps = track.compStatus ? COMP_STEPS : REGULAR_STEPS;
  const missingCompRegistration =
    trackLoaded && track.compStatus && !data.track.compRegistration.id;

  useEffect(() => {
    if (missingCompRegistration) {
      setActiveStep('division');
    } else if (isRegistered) {
      setActiveStep('confirmation');
    } else if (currentUser) {
      setActiveStep('join');
    }
  }, [currentUser, setActiveStep, isRegistered, missingCompRegistration]);

  const activeStepIndex = steps.indexOf(activeStep);

  return (
    <Layout titlePrefixes={[track.name]}>
      <Header />

      <section>
        <div className="container">
          <div className="section-heading text-center">
            <Box
              flexDirection="column"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Avatar className={styles.avatar} src={track.imageUrl} />
              {track.source.name !== track.name && (
                <Typography variant="subtitle1">{track.source.name}</Typography>
              )}
              <Typography variant="h4">{track.name}</Typography>
            </Box>
            <div className={styles.root}>
              {allowRegistration ? (
                <>
                  <Stepper activeStep={activeStepIndex} alternativeLabel>
                    {steps.map((label, i) => (
                      <Step
                        key={label}
                        completed={
                          i < activeStepIndex ||
                          (activeStepIndex === steps.length - 1 &&
                            i === activeStepIndex)
                        }
                      >
                        <StepLabel>{STEPS[label]}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                  <div>
                    {activeStep === 'login' && (
                      <div>
                        <Box>
                          <FormControlLabel
                            className={styles.accept}
                            control={
                              <Checkbox
                                checked={termsAccepted}
                                onChange={() =>
                                  setTermsAccepted((value) => !value)
                                }
                                name="checkedB"
                                color="primary"
                              />
                            }
                            label={
                              <Typography variant="body2" align="left">
                                I have read and accept ErgZone's{' '}
                                <Link to="/privacy">privacy policy</Link> and{' '}
                                <Link to="/terms">terms</Link>.
                              </Typography>
                            }
                          />
                        </Box>
                        <Button
                          className={styles.button}
                          disabled={!termsAccepted}
                          variant="contained"
                          color="primary"
                          onClick={() => redirectToAuth()}
                        >
                          Login
                        </Button>
                      </div>
                    )}
                    {activeStep === 'division' && (
                      <DivisionStep
                        track={track}
                        compRegistration={data.track.compRegistration}
                        currentUser={currentUser}
                        reload={refetch}
                      />
                    )}
                    {activeStep === 'join' && (
                      <div>
                        <Box className={styles.instructions}>
                          {currentUser ? (
                            <>
                              Hi <strong>{currentUser.name}</strong>{' '}
                              <em> ({currentUser.c2Username})</em>,{' '}
                            </>
                          ) : (
                            ' '
                          )}
                          please click the button below to
                          {canJoin
                            ? ' join the group.'
                            : canRequestAccess
                            ? ' request access to the group.'
                            : ''}
                        </Box>
                        <Button
                          className={styles.button}
                          variant="contained"
                          disabled={loading}
                          color="primary"
                          onClick={() =>
                            toggleTrackMutation({ variables: { id: track.id } })
                          }
                        >
                          {canJoin
                            ? 'Join'
                            : canRequestAccess
                            ? 'Request Access'
                            : ''}
                        </Button>
                      </div>
                    )}
                    {activeStep === 'confirmation' && (
                      <>
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                          justifyContent="center"
                        >
                          {hasJoined && (
                            <Alert severity="success">
                              <AlertTitle>
                                Congratulations
                                {currentUser ? ` ${currentUser.name}` : ''}. You
                                are now part of <strong>'{track.name}'</strong>
                              </AlertTitle>
                              Open the ErgZone app{' '}
                              {track.compStatus
                                ? 'to see the comp details.'
                                : 'to see the new workouts.'}
                            </Alert>
                          )}
                          {isPendingApproval && (
                            <Alert severity="success">
                              <AlertTitle>
                                Hi {currentUser ? ` ${currentUser.name}` : ''},
                                your request has been sent and is waiting
                                approval
                              </AlertTitle>
                              Once an administrator of the group approves your
                              request, you'll be able to see the workouts.
                            </Alert>
                          )}
                          {isMobile() && (
                            <Button
                              className={styles.openApp}
                              color="primary"
                              href="https://app.erg.zone/"
                              variant="contained"
                            >
                              Open app
                            </Button>
                          )}
                        </Box>
                      </>
                    )}
                  </div>
                </>
              ) : (
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  marginTop={2}
                >
                  <Alert severity="warning">This group is closed.</Alert>
                </Box>
              )}
              {!!description ? (
                <Box
                  className={`${styles.descriptionContainer} markdownContainer`}
                >
                  <div dangerouslySetInnerHTML={{ __html: description }} />
                </Box>
              ) : !!track.description ? (
                <Box className={styles.descriptionContainer}>
                  <h4>Description</h4>
                  <Box className={styles.instructions}>
                    <Linkify>{track.description}</Linkify>
                  </Box>
                </Box>
              ) : null}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  );
};

export default TrackJoinPage;
