const TRACK_FRAGMENT = `
id
name
webPath
compStatus
compDate
compDateDisplay
compExtraFields {
  name
  type
  required
}
discoverable
imageUrl
features
featuredImage
registrationDescription
idToken
description
planWeeks
trialPrice {
  id
}
activePrices {
  id
  name
  displayText
  amountCents
  recurringMonths
}
activePrice {
  id
  name
  displayText
  amountCents
  bundle {
    discounts {
      amountCents
      min
    }
    prices {
      id
      name
      displayText
      amountCents
      recurringMonths
    }
  }
}
source {
  id
  name
  slug
  imageUrl
}
`;

exports.TRACK_FRAGMENT = TRACK_FRAGMENT;
